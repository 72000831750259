<template>
  <!-- 资方公司 -->
  <div class="managementCompanyBox">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
      class="form_Search"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="addRow"
        >
          新增资方公司
        </el-button>
      </div>
      <Table
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
      />
      <!-- 弹出的新增跟修改框 -->
      <Dialog
        ref="addAndMoneySide"
        dialog-width="35%"
        label-width="140px"
        :edit-form-data="editFormData"
        :form-item="addAndEditFormItem"
        @getFormData="getFormData"
      />

      <!-- 分页  -->
      <Pagination
        :params="formInline"
        :total="total"
        :get-data-list="getdata"
        :in-article="listData.length"
      />
    </div>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { warehouseList } from '@/api/warehousingSystem'
import {
  addAndManagementCompany,
  obtainManagementCompany,
  deleteManagementCompany,
  putManagementCompany
} from '@/api/managementCompany'
import { rules, differentLengths } from '@/utils/matchFormRegex'

export default {
  components: { FormSearch, Table, Pagination, Dialog },
  data() {
    return {
      itemData: [
        { label: '资方公司名称', prop: 'funderName' },
        { label: '统一社会信用代码', prop: 'funderUnicode' },
        { label: '监管仓库', prop: 'warehousesName' },
        { label: '联系人', prop: 'contract' },
        { label: '联系电话', prop: 'contractPhone' }
      ],
      formItemArr: [
        { type: 'input', label: '资方公司', value: 'funderName' },
        { type: 'input', label: '统一社会信用代码', value: 'funderUnicode' }
      ],
      loading: false,
      warehouseList: [], // 监管仓库
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.addRow },
        { bType: 'primary', label: '删除', handleEvent: this.deleteFormData }
      ],
      editFormData: {},
      warehouseArray: [],
      // 新增
      addAndEditFormItem: [
        {
          type: 'input',
          label: '资方公司名称',
          value: 'funderName',
          disabled: false,
          rules: differentLengths(1, 25)
        },
        {
          type: 'input',
          label: '统一社会信用代码',
          value: 'funderUnicode',
          rules: [...rules.unicode]
        },
        {
          type: 'select',
          label: '监管仓库',
          value: 'warehouseArr',
          multiple: true,
          optionLabel: 'warehouseName',
          optionId: 'id',
          child: []
        },
        {
          type: 'select',
          label: '银行类型',
          value: 'bankCode',
          optionLabel: 'dictName',
          optionId: 'dictId',
          child: this.$store.getters.getDictionaryItem('PUSH_BANK_TYPE')
        },
        {
          type: 'input',
          label: '联系人',
          value: 'contract',
          rules: differentLengths(1, 15)
        },
        {
          type: 'input',
          label: '联系电话',
          value: 'contractPhone',
          rules: [...rules.phone]
        }
      ],
      // 表格数据
      // 表格数据
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0
    }
  },
  mounted() {
    // 仓库列表
    warehouseList('', res => {
      this.addAndEditFormItem.forEach(item => {
        this.warehouseArray = [...res.data]
        if (item.value === 'warehouseArr') {
          item.child = [...res.data]
        }
      })
    })
  },
  methods: {
    // 添加确认
    getFormData(value) {
      value.warehouseList = []
      this.warehouseArray.forEach(item => {
        if (value.warehouseArr.includes(item.id)) {
          value.warehouseList.push({ warehouseId: item.id, warehouseName: item.warehouseName })
        }
      })
      if (value.id) {
        putManagementCompany(value, () => {
          this.$message.success('修改成功')
          this.$refs.addAndMoneySide.editFormVisible = false
          this.getdata()
        })
        return
      }
      addAndManagementCompany(value, () => {
        this.$message.success('添加成功')
        this.$refs.addAndMoneySide.editFormVisible = false
        this.getdata()
      })
    },
    //   删除
    deleteFormData(row) {
      this.$confirm(`此操作将删除此条信息记录，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteManagementCompany(row.id, () => {
            this.$message.success('删除成功！')
            this.getdata(true)
          })
        })
        .catch(() => {})
    },

    // 添加  编辑
    addRow(row) {
      this.$refs.addAndMoneySide.editFormVisible = true
      if (row.constructor === Object) {
        this.addAndEditFormItem[0].disabled = true
        this.editFormData = { ...row }
      } else {
        this.addAndEditFormItem[0].disabled = false
        this.editFormData = {}
      }
    },
    // 获取列表  --搜索
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      obtainManagementCompany(this.formInline, res => {
        this.listData = res.data.pageData
        this.listData.forEach(item => {
          item.warehouseArr = []
          item.warehousesName = ''
          item.warehouses.forEach(val => {
            item.warehouseArr.push(val.warehouseId)
            item.warehousesName += val.warehouseName + ' '
          })
        })
        this.total = res.data.total
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.managementCompanyBox {
  .form-search {
    /deep/.el-form-item {
      .el-form-item__label {
        width: 130px !important;
      }
    }
  }

  .cooperativeEnterprise {
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 500;
    color: #333;
  }
}
</style>
